<template>
  <v-container class="pa-0 app-fill-height mobile-container">
    <v-container tag="section" class="pa-0 mb-10 mobile-container">
      <v-responsive
        max-width="800px"
        class="mx-auto"
      >
          <v-card class="page-box-wrapper elevation-3">
            <div class="page-box-content">
              <h2 class="h2-green">
                Набор <br />
                "Супер мозг"
              </h2>
              <div class="img-top-box">
                <img
                  src="/img/made-in-germany.png"
                  alt="Made in Germany"
                  class="germany-top"
                />
                <div class="text-center">
                  <img
                    src="/img/super_brain/super-brain-set.jpg"
                    alt="Набор Обмен веществ"
                    class="super-brain-set"
                  />
                </div>
              </div>
              <p class="mb-7 px-3">
                Стабилизация нервной системы, быстрый заряд энергии,
                максимальная концентрация, повышение когнитивных способностей,
                улучшение памяти и снижение деструктивных реакций на стресс.
              </p>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">Синергия</h2>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <div class="girl-mind">
                        <img src="/img/super_brain/girl-mind.png" alt="" />
                      </div>
                      <div class="col-text">
                        <h3 class="h3-col-title">Майнд Мастер</h3>
                        <p class="p-col">
                          Антистресс – как основа всех процессов
                        </p>
                      </div>
                    </v-col>
                    <div class="synergy-box-mobile">
                      <img src="/img/metabolism/synergy.png" alt="" />
                    </div>
                    <v-col cols="12" sm="6">
                      <div class="omega-nerv">
                        <img src="/img/super_brain/omega-nerv.png" alt="" />
                      </div>
                      <div class="col-text">
                        <h3 class="h3-col-title">Супер омега</h3>
                        <p class="p-col">
                          Источник материала для нервных волокон
                        </p>
                      </div>
                    </v-col>
                  </v-row>
                  <div class="synergy-box">
                    <img src="/img/metabolism/synergy.png" alt="" />
                  </div>
                  <div class="synergy-box-mobile">
                    <img src="/img/metabolism/synergy.png" alt="" />
                  </div>
                  <v-row>
                    <v-col cols="12" sm="6">
                      <div class="pro-balance-avatar">
                        <img src="/img/super_brain/pb-avatar.png" alt="" />
                      </div>
                      <div class="col-text">
                        <h3 class="h3-col-title">Про баланс</h3>
                        <p class="p-col">
                          Источник магния и компонентов электролитного баланса
                        </p>
                      </div>
                    </v-col>
                    <div class="synergy-box-mobile">
                      <img src="/img/metabolism/synergy.png" alt="" />
                    </div>
                    <v-col cols="12" sm="6">
                      <div class="pro12-avatar">
                        <img src="/img/metabolism/pro12-avatar.png" alt="" />
                      </div>
                      <div class="col-text">
                        <h3 class="h3-col-title">Про 12</h3>
                        <p class="p-col">Помощь кишечнику – второму мозгу</p>
                      </div>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">Майнд мастер</h2>
                  <v-row class="mt-3">
                    <v-col cols="12" sm="3" class="text-center">
                      <img
                        src="/img/super_brain/mind-master-pic.png"
                        alt=""
                        class="mind-master-pic"
                      />
                    </v-col>
                    <v-col cols="12" sm="9">
                      <h3 class="h3-green">ВИТАМИНЫ ГРУППЫ В</h3>
                      <p class="mt-3">
                        Витамины группы В являются кофакторами (помощниками)
                        многих ферментативных реакций, необходимых для синтеза и
                        транспорта нейромедиаторов, а также для процесса
                        создания нервных волокон спинного и головного мозга.
                      </p>
                      <p class="mt-3">
                        Синергизм витаминов группы В дает такие эффекты, как
                        торможение гибели нейронов, антиоксидантное и
                        нейротрофическое (рост нервной ткани) действие,
                        детоксикация, снижение нейровоспаления и широкий круг
                        метаболических эффектов.
                      </p>
                      <h3 class="h3-green mt-5">ВИТАМИН Е</h3>
                      <p class="mt-3">
                        Витамин Е - мощный антиоксидант, он тормозит процесс
                        окисления полиненасыщенных жирных кислот, из которых
                        состоят мембраны всех клеток человеческого тела, защищая
                        их от пагубного воздействия.
                      </p>
                    </v-col>
                  </v-row>
                  <h3 class="h3-green mt-5">ВИТАМИН C</h3>
                  <p class="mt-3">
                    Концентрация витамина С в спинномозговой жидкости примерно в
                    4 раза больше, чем в плазме, и примерно в 200 раз больше в
                    нейронах, чем в плазме. Аскорбиновая кислота оказывает
                    положительный эффект на процессы памяти и вычислительные
                    способности, а низкий уровень витамина С в крови – фактор
                    риска снижения когнитивных функций.
                  </p>
                  <h3 class="h3-green mt-5">Железо</h3>
                  <p class="mt-3">
                    Результаты экспериментальных исследований показали, что
                    дефицит железа может привести к изменению поведенческих
                    реакций, познавательных функций, негативно повлиять на
                    способность к обучению и память.
                  </p>
                  <h3 class="h3-green mt-5">Селен</h3>
                  <p class="mt-3">
                    Селен важен для поддержания нормальной функции мозга, а его
                    дефицит связан с развитием таких возрастных
                    нейродегенеративных патологий, как болезни Альцгеймера и
                    Паркинсона.
                  </p>
                  <div
                    class="wistia_responsive_padding mt-7"
                    style="padding: 56.25% 0 0 0; position: relative"
                  >
                    <div
                      class="wistia_responsive_wrapper"
                      style="
                        height: 100%;
                        left: 0;
                        position: absolute;
                        top: 0;
                        width: 100%;
                      "
                    >
                    <iframe src="https://fast.wistia.net/embed/iframe/de5zpbqp6r?web_component=true&seo=true" title="Майнд Мастер Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" width="100%" height="100%"></iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">Супер омега</h2>
                  <v-row class="mt-5">
                    <v-col cols="12" sm="3" class="text-center">
                      <img
                        src="/img/products/super-omega.png"
                        alt=""
                        class="img-super-omega"
                      />
                    </v-col>
                    <v-col cols="12" sm="9">
                      <h3 class="h3-green">Содержание в 3 капсулах:</h3>
                      <ul class="ul-check">
                        <li>
                          <v-icon icon="mdi-check">mdi-check</v-icon>
                          ПНЖК Омега-3, в т.ч.: 1260 мг 63% от суточной
                          потребности
                        </li>
                        <li>
                          <v-icon icon="mdi-check">mdi-check</v-icon>
                          Эйкозапентаеновая кислота (EPA) 630 мг 105% от
                          суточной потребности (не превышает допустимого уровня)
                        </li>
                        <li>
                          <v-icon icon="mdi-check">mdi-check</v-icon>
                          Докозагексаеновая кислота (DHA) 420 мг 60% от суточной
                          потребности
                        </li>
                        <li>
                          <v-icon icon="mdi-check">mdi-check</v-icon>
                          ПНЖК Омега-6, в т.ч.:
                        </li>
                        <li>
                          <v-icon icon="mdi-check">mdi-check</v-icon>
                          Линолевая 330 мг 33% от суточной потребности
                        </li>
                        <li>
                          <v-icon icon="mdi-check">mdi-check</v-icon>
                          γ-линоленовая 70 мг 12% от суточной потребности
                        </li>
                        <li>
                          <v-icon icon="mdi-check">mdi-check</v-icon>
                          Бета-глюканы 114 мг 57% от суточной потребности
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/super_brain/friends-of-the-sea.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>
                        Обладает серитфикатом Friend of the sea (экологически
                        чистое сырье) – только филейные части свободно живущих
                        рыб
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/super_brain/bez_gmo.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>Не содержит ГМО и лактозу</p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/super_brain/brain-head-omega.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>
                        Омега-3 поглощаются мембранами нейронов, обеспечивают
                        передачу импульсов между ними и улучшают
                        функционирование рецепторов, расположенных на мембранах
                        нейронов
                      </p>
                    </v-col>
                  </v-row>
                  <div
                    class="wistia_responsive_padding mt-7"
                    style="padding: 56.25% 0 0 0; position: relative"
                  >
                    <div
                      class="wistia_responsive_wrapper"
                      style="
                        height: 100%;
                        left: 0;
                        position: absolute;
                        top: 0;
                        width: 100%;
                      "
                    >
                    <iframe src="https://fast.wistia.net/embed/iframe/wfoa7vw22c?web_component=true&seo=true" title="Супер Омега Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" width="100%" height="100%"></iframe>
                    </div>
                  </div>
                  <h2 class="h2-title-bg mt-7 mb-5">
                    ОМЕГА-3 ПНЖК ИГРАЮТ ВАЖНУЮ РОЛЬ В:
                  </h2>
                  <ul class="ul-dot">
                    <li>
                      <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                      нейрогенезе (образование новых клеток)
                    </li>
                    <li>
                      <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                      нейротрансмиссии (передача нервного импульса от одного
                      нейрона к другому)
                    </li>
                    <li>
                      <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>

                      защите от окислительного стресса
                    </li>
                    <li>
                      <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                      нейропротекции (комплекс механизмов, противодействующих
                      повреждающим факторам)
                    </li>
                  </ul>
                  <p class="text-center mt-5 mb-3">
                    <strong>
                      Именно поэтому омега-3 и омега-6 ПНЖК крайне необходимы
                      для поддержки нормальной функции мозга
                    </strong>
                  </p>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">Про баланс</h2>
                  <v-row class="mt-5">
                    <v-col cols="12" sm="3" class="text-center">
                      <img
                        src="/img/products/pro-balance.png"
                        alt=""
                        class="img-pro-balance"
                      />
                    </v-col>
                    <v-col cols="12" sm="9">
                      <p>
                        Употребление минералов помогает поддерживать
                        кислотно-щелочной баланс, а также работоспособность
                        буферных систем. В случае нарушения pH минеральные
                        вещества помогают организму быстрее вернуться в
                        равновесие.
                      </p>
                      <p class="mt-3">
                        Для оптимальной работы этих ферментов необходима
                        внеклеточная сбалансированная «среда». То есть в этой
                        среде должно быть здоровое соотношение кислот и щелочей.
                      </p>
                      <p class="mt-3">
                        Любое изменение кислотно-щелочного баланса тормозит
                        нормальные процессы и ведет к различным нарушениям и
                        заболеваниям.
                      </p>
                    </v-col>
                  </v-row>
                  <h3 class="h3-green mt-5">КАЛЬЦИЙ И МАГНИЙ</h3>
                  <p class="mt-3">
                    Элементы участвуют в самых основных процессах
                    жизнедеятельности клеток, в том числе и нервных клеток. Эти
                    ионы обладают и рядом специфических именно для нервной
                    системы свойств.
                  </p>
                  <h3 class="h3-green mt-5">МЕДЬ</h3>
                  <p class="mt-3">
                    Исследования показывают, что снижение уровня меди в
                    организме может вызывать снижение когнитивных функций. Медь
                    входит в состав защитных оболочек нервных волокон.
                  </p>
                  <h3 class="h3-green mt-5">НАТРИЙ</h3>
                  <p class="mt-3">
                    Основной внеклеточный ион, принимающий участие в генерации и
                    передаче электрических нервных сигналов.
                  </p>
                  <v-row class="mt-5">
                    <v-col cols="12" sm="3" class="text-center">
                      <img
                        src="/img/super_brain/girl-emotions.jpg"
                        alt=""
                        class="girl-emotions"
                      />
                    </v-col>
                    <v-col cols="12" sm="9">
                      <p>
                        Кислотно-щелочное равновесие способствует улучшению
                        общего хорошего самочувствия, снижению усталости.
                      </p>
                      <p class="mt-3">
                        Про Баланс восполняет недостаток минералов в организме,
                        способствует нормальному функционированию нервной
                        системы.
                      </p>
                    </v-col>
                  </v-row>
                  <div
                    class="wistia_responsive_padding mt-7"
                    style="padding: 56.25% 0 0 0; position: relative"
                  >
                    <div
                      class="wistia_responsive_wrapper"
                      style="
                        height: 100%;
                        left: 0;
                        position: absolute;
                        top: 0;
                        width: 100%;
                      "
                    >
                    <iframe src="https://fast.wistia.net/embed/iframe/e1mppkr7sh?web_component=true&seo=true" title="Про Баланс Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" width="100%" height="100%"></iframe>
                    </div>
                  </div>
                  <h2 class="h2-title-bg mt-5 mb-5">
                    Микронутриенты в составе
                  </h2>
                  <div class="micro-div">
                    <h4 class="h4-icon">
                      <span>K</span>
                      Калий (300 мг)
                    </h4>
                    <ul class="ul-dot">
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Регуляция водного, кислотного и электролитного баланса
                      </li>
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Участвует в регуляции уровня сахара в крови
                      </li>
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Помогает поддерживать щелочную среду в организме
                      </li>
                    </ul>
                  </div>
                  <div class="micro-div">
                    <h4 class="h4-icon">
                      <span>Mg</span>
                      Магний (200 мг)
                    </h4>
                    <ul class="ul-dot">
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Энергетический метаболизм
                      </li>
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Синтез белков
                      </li>
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        <p>
                          Магний является неотъемлемой составной частью активных
                          центров ферментов.
                        </p>
                        <p class="mt-3">
                          Гликолиз — один из наиболее фундаментальных
                          биохимических путей углеводного метаболизма.
                        </p>
                        <p class="mt-3">
                          Дефицит магния неизбежно приведет к значительному
                          снижению активности по крайней мере шести из 10
                          гликолитических ферментов, способствуя развитию
                          глюкозотолерантности тканей.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div class="micro-div">
                    <h4 class="h4-icon">
                      <span>Ca</span>
                      Кальций (695 мг)
                    </h4>
                    <ul class="ul-dot">
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Участвует в процессах образования энергии
                      </li>
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Входит в состав многих ферментов, нормализующих обмен
                        веществ
                      </li>
                    </ul>
                  </div>
                  <div class="micro-div">
                    <h4 class="h4-icon">
                      <span>Na</span>
                      Натрий (550 мг)
                    </h4>
                    <ul class="ul-dot">
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Основной внеклеточный ион, принимающий участие в
                        переносе воды, глюкозы крови
                      </li>
                    </ul>
                  </div>
                  <div class="micro-div">
                    <h4 class="h4-icon">
                      <span>Cu</span>
                      Медь (453 мкг)
                    </h4>
                    <ul class="ul-dot">
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Стимулирует усвоение белков и углеводов
                      </li>
                    </ul>
                  </div>
                  <div class="micro-div">
                    <h4 class="h4-icon">
                      <span>Cr</span>
                      Хром (60 мкг)
                    </h4>
                    <ul class="ul-dot">
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Участвует в регуляции уровня глюкозы крови, усиливая
                        действие инсулина
                      </li>
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        <p>
                          Участвует в регуляции углеводного и липидного обменов,
                          поддерживая нормальный уровень толерантности организма
                          к глюкозе.
                        </p>
                        <p class="mt-3">
                          Биологически активная форма хрома образует комплексное
                          соединение с инсулином, более активное, чем свободный
                          инсулин.
                        </p>
                      </li>
                    </ul>
                  </div>
                  <div class="micro-div">
                    <h4 class="h4-icon">
                      <span>Mo</span>
                      Молибден (80 мкг)
                    </h4>
                    <ul class="ul-dot">
                      <li>
                        <v-icon icon="mdi-circle-small" size="x-large">mdi-circle-small</v-icon>
                        Способствует метаболизму углеводов и жиров
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">Про 12</h2>
                  <v-row class="mt-5">
                    <v-col cols="12" sm="3" class="text-center">
                      <img
                        src="/img/products/pro12.png"
                        alt=""
                        class="pro12-img"
                      />
                    </v-col>
                    <v-col cols="12" sm="9">
                      <h3 class="h3-green">
                        Микробиота кишечника принимает участие:
                      </h3>
                      <ul class="ul-check">
                        <li>
                          <v-icon icon="mdi-check">mdi-check</v-icon>
                          в метаболизме белков, жиров и углеводов
                        </li>
                        <li>
                          <v-icon icon="mdi-check">mdi-check</v-icon>
                          в обеспечении организма энергией
                        </li>
                        <li>
                          <v-icon icon="mdi-check">mdi-check</v-icon>
                          в рециркуляции желчных кислот
                        </li>
                        <li>
                          <v-icon icon="mdi-check">mdi-check</v-icon>
                          в детоксикационной функции
                        </li>
                        <li>
                          <v-icon icon="mdi-check">mdi-check</v-icon>
                          в оксиданто/антиоксидантной активности
                        </li>
                      </ul>
                    </v-col>
                  </v-row>
                  <div class="text-center mt-5">
                    <img
                      src="/img/super_brain/gkt-brain.png"
                      alt=""
                      class="gkt-brain"
                    />
                    <p class="mt-5 mb-3">
                      <strong>
                        Микробиота является важной частью оси «кишечник – мозг»
                      </strong>
                    </p>
                  </div>
                  <h2 class="h2-title-bg mt-7 mb-5">
                    ВЛИЯНИЕ МИКРОБИОТЫ НА НЕРВНУЮ СИСТЕМУ ПОДТВЕРЖДАЕТСЯ
                    СОВРЕМЕННЫМИ НАУЧНЫМИ ДАННЫМИ:
                  </h2>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/super_brain/brain-puzzle.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>
                        Определена корреляция между составом микробиоты,
                        умственной работоспособностью, познавательной
                        активностью и качеством сна
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/super_brain/emotions-icon.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>
                        У людей с дисбиозом меняется функция мозговых центров,
                        отвечающих за эмоции
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/super_brain/read-book-icon.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>
                        Прием пробиотиков положительно влияет на когнитивные
                        функции
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/super_brain/emotion-fon-icon.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>
                        Использование пребиотиков сопровождается улучшением
                        эмоционального фона и кратковременной памяти
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/super_brain/brain-return-icon.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>
                        Синбиотики улучшают кратковременную и отсроченную память
                      </p>
                    </v-col>
                  </v-row>
                  <div
                    class="wistia_responsive_padding mt-7"
                    style="padding: 56.25% 0 0 0; position: relative"
                  >
                    <div
                      class="wistia_responsive_wrapper"
                      style="
                        height: 100%;
                        left: 0;
                        position: absolute;
                        top: 0;
                        width: 100%;
                      "
                    >
                    <iframe src="https://fast.wistia.net/embed/iframe/c11mmob7ui?web_component=true&seo=true" title="Про 12 Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" width="100%" height="100%"></iframe>
                    </div>
                  </div>
                  <h2 class="h2-title-bg mt-7 mb-5">
                    Положительные эффекты КЦЖК
                  </h2>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/bifidobacteria.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>Стимуляция роста бифидобактерий</p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/pathogens.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>Подавление патогенов</p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/toxic.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>
                        Снижение уровня токсических метаболитов и канцерогенов
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/body-energy-supply.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>Энергообеспечение организма</p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/normalization-gastric.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>Нормализация моторики ЖКТ</p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/microelements.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>Улучшение всасывания Na, K, Ca, Mg, Zn</p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/liver-protection.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>Усиление гликогенеза в печени</p>
                    </v-col>
                  </v-row>
                  <v-row class="row-icon">
                    <v-col cols="2" sm="3" class="col-img-center">
                      <img
                        src="/img/metabolism/anti-inflammatory.png"
                        alt=""
                        class="img-icon-avatar"
                      />
                    </v-col>
                    <v-col cols="10" sm="9" class="col-center">
                      <p>Противовоспалительное действие</p>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div class="box-border-wrapper mb-3">
                <div class="box-border-content">
                  <h2 class="h2-title-bg">Майнд мастер экстрим</h2>
                  <div class="text-center mt-5 mb-3">
                    <img
                      src="/img/super_brain/mm-extreme.png"
                      alt=""
                      class="mind-master-extreme"
                    />
                  </div>
                  <h3 class="h3-green">КОФЕИН</h3>
                  <p class="mt-3">
                    Кофеин оказывает прямое возбуждающее влияние на ЦНС:
                    регулирует и усиливает процессы возбуждения в коре головного
                    мозга, дыхательном и сосудодвигательном центре, активирует
                    положительные условные рефлексы и двигательную активность.
                  </p>
                  <p class="mt-3">
                    Стимулирует психическую деятельность, повышает умственную и
                    физическую работоспособность, укорачивает время реакций.
                    После приема появляется бодрость, временно устраняются или
                    уменьшаются утомление и сонливость.
                  </p>
                  <h3 class="h3-green mt-5">КОЭНЗИМ Q10</h3>
                  <p class="mt-3">
                    Коэнзим Q10 является одним из незаменимых факторов,
                    принимающих участие в синтезе АТФ. Поскольку нейроны — это
                    самые энергоемкие клетки организма, в мозге метаболический
                    дефицит проявляется в первую очередь.
                  </p>
                  <p class="mt-3">
                    Даже небольшое уменьшение содержания АТФ приводит к
                    замедлению процессов мышления и запоминания, снижению
                    работоспособности и концентрации внимания, появлению общей
                    слабости и т. д. Коэнзим Q10 — один из мощнейших
                    антиоксидантов.
                  </p>
                  <div class="text-center mt-5 mb-3">
                    <img
                      src="/img/super_brain/brain-super.jpg"
                      alt=""
                      class="brain-super-img"
                    />
                  </div>
                  <h3 class="h3-green">Таурин</h3>
                  <p class="mt-3">
                    Таурин стимулирует образование новых клеток в гиппокампе
                    (часть мозга, отвечающая за память и эмоции),
                    восстанавливает нервные клетки.
                  </p>
                  <div
                    class="wistia_responsive_padding"
                    style="padding: 56.25% 0 0 0; position: relative"
                  >
                    <div
                      class="wistia_responsive_wrapper"
                      style="
                        height: 100%;
                        left: 0;
                        position: absolute;
                        top: 0;
                        width: 100%;
                      "
                    >
                    <iframe src="https://fast.wistia.net/embed/iframe/jlxfqxqftn?web_component=true&seo=true" title="Майнд Мастер Экстрим Video" allow="autoplay; fullscreen" allowtransparency="true" frameborder="0" scrolling="no" class="wistia_embed" name="wistia_embed" width="100%" height="100%"></iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div class="text-center mt-10" id="contacts">
                <h3 class="h3-contacts">
                  За подробной консультацией по набору напишите мне
                </h3>
                <v-icon
                  icon="mdi-chevron-triple-down"
                  size="x-large"
                  color="#94b894"
                  class="mt-5"
                ></v-icon>
                <div class="mt-12 mb-7">
                  <div class="d-flex grow flex-wrap">
                        <div
                            class="contact-avatar v-avatar mx-auto v-card--material__avatar elevation-6 grey"
                            style="height: 128px; min-width: 128px; width: 128px"
                        >
                            <div class="v-image v-responsive theme--light">
                                <img v-if="userData.avatar"
                                     :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                     alt=""
                                >
                                <div class="v-responsive__content" style="width: 250px"></div>
                            </div>
                        </div>

                        <v-card-text class="text-center">
                            <h4 class="display-2 font-weight-light mb-3 black--text">
                                {{ userData.name }} {{ userData.last_name }}
                            </h4>
                        </v-card-text>
                    </div>
                    <v-row>
                        <v-col class="text-center">
                            <div class="input-group mb-3">
                                <v-tooltip
                                    top
                                    v-if="userData.phone_whatsapp"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <a
                                            style="text-decoration: none"
                                            :href="`https://wa.me/${userData.phone_whatsapp}`"
                                            target="_blank"
                                        >
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                class="mr-4"
                                                color="success"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>mdi-whatsapp</v-icon>
                                            </v-btn>
                                        </a>
                                    </template>
                                    <span>Связаться в WhatsApp</span>
                                </v-tooltip>

                                <v-tooltip
                                    top
                                    v-if="userData.telegram"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <a
                                            style="text-decoration: none"
                                            :href="`${userData.telegram}`"
                                            target="_blank"
                                        >
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                class="mr-4"
                                                color="blue"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>fab fa-telegram-plane</v-icon>
                                            </v-btn>
                                        </a>
                                    </template>
                                    <span>Связаться в Telegram</span>
                                </v-tooltip>
                            </div>
                        </v-col>
                    </v-row>
                </div>
              </div>
            </div>
          </v-card>
      </v-responsive>
    </v-container>
  </v-container>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "SuperBrain",
    data: () => ({
        cookieShow: true,
    }),
    metaInfo: {
        title: 'Набор Супер мозг от LR Health & Beauty',
        meta: [
            { vmid: 'description', property: 'description', content: 'Стабилизация нервной системы, быстрый заряд энергии, максимальная концентрация, повышение когнитивных способностей, улучшение памяти и снижение деструктивных реакций на стресс' },
            { vmid: 'og:title', property: 'og:title', content: 'Набор Супер мозг от LR Health & Beauty' },
            { vmid: 'og:description', property: 'og:description', content: 'Стабилизация нервной системы, быстрый заряд энергии, максимальная концентрация, повышение когнитивных способностей, улучшение памяти и снижение деструктивных реакций на стресс' },
            { vmid: 'og:image', property: 'og:image', content: '' },
        ],
    },
    created() {
        if (!this.$route.query.p) {
            if (this.$cookies.isKey('lifeplus_partner')) {
                const partnerNum = this.$cookies.get('lifeplus_partner')
                this.$router.push ({name: 'SuperBrain', query: { p: partnerNum }})
            }
        } else {
            this.$cookies.set('lifeplus_partner', this.$route.query.p, '6m')
        }

        if (this.$cookies.isKey('lifeplus_cookie')) {
            this.cookieShow = false
        }
    },
    computed: {
        ...mapGetters({
            userData: 'user/getPartnerAccess'
        }),
    },
    methods: {
        ...mapActions({
            addNotification: 'application/addNotification',
            showPartnerAccess: 'user/showPartnerAccess',
        }),
        cookieAccept() {
            this.cookieShow = false
            this.$cookies.set('lifeplus_cookie', 'accept', '12m')
        },
    },
    mounted () {
        this.showPartnerAccess(this.$route.query.p)
    },
}
</script>

<style lang="scss" scoped>
@font-face {
  font-family: raleway-b;
  src: url("/fonts/raleway-black.woff2");
  font-weight: 300;
}
.app-fill-height {
  font-family: Roboto,sans-serif;
  font-size: 16px !important;
}
ul li {
  margin-bottom: 5px;
}
.contact-avatar {
  top:-30px;
  bottom:0;
}
.page-box-wrapper {
  background: linear-gradient(to right, #99cb9c, #a0c9c8);
  padding: 10px;
}
.page-box-content {
  background-color: #fff;
  padding: 10px 5px 20px;
  border-radius: 3px;
}
.h2-green {
  font-family: raleway-b;
  font-size: calc(24px + (36 - 24) * ((100vw - 320px) / (800 - 320)));
  text-transform: uppercase;
  text-align: center;
  color: #94b894;
}
.box-border-wrapper {
  background: linear-gradient(to right, #99cb9c, #a0c9c8);
  padding: 2px;
  border-radius: 5px;
}
.box-border-content {
  background-color: #fff;
  border-radius: 3px;
  padding: 10px;
}
.img-top-box {
  margin: 20px 0;
}
.germany-top {
  position: absolute;
  width: calc(40px + (70 - 40) * ((100vw - 320px) / (800 - 320)));
  left: 15%;
}
.super-brain-set {
  max-width: 480px;
  width: 100%;
}
.h2-title-bg {
  text-align: center;
  font-family: raleway-b;
  color: #fff;
  text-transform: uppercase;
  background: linear-gradient(to right, #99cb9c, #a0c9c8);
  border-radius: 4px;
}
.girl-mind,
.omega-nerv,
.pro-balance-avatar,
.pro12-avatar {
  text-align: center;
  margin-top: 20px;
}
.girl-mind img {
  width: 80%;
}
.omega-nerv img {
  width: 80%;
}
.pro-balance-avatar img {
  width: 80%;
}
.pro12-avatar img {
  width: 72.6%;
}
.synergy-box {
  position: relative;
}
.synergy-box-mobile {
  display: none;
}
.synergy-box img {
  position: absolute;
  width: 80px;
  left: 43%;
}
.h3-col-title {
  text-align: center;
  text-transform: uppercase;
  margin-top: 20px;
}
.p-col {
  text-align: center;
  margin-bottom: 20px;
}
.mind-master-pic {
  width: 100%;
}
.h3-green {
  text-align: center;
  font-family: raleway-b;
  color: #94b894;
  text-transform: uppercase;
}
.brain-super-img {
  width: 100%;
  max-width: 300px;
  border-radius: 5px;
}
.title-orange {
  text-align: center;
  font-family: raleway-b;
  color: #eab355;
  text-transform: uppercase;
}
.h4-title {
  text-align: center;
  font-family: raleway-b;
  color: #94b894;
  text-transform: uppercase;
}
.av-honey,
.av-peach {
  width: 40px;
}
.h4-honey {
  color: #ffbc02;
}
.h4-peach {
  color: #ff8902;
}
.h4-green {
  color: #94b894;
}
.girl-emotions {
  border-radius: 5px;
  width: 150px;
}
.av-leaf-avatar,
.honey-avatar {
  width: 80px;
  height: 80px;
  padding: 3px;
  border-radius: 50%;
  border: 3px solid #94b894;
}
.mind-master-extreme {
  max-width: 250px;
  width: 100%;
}
.reishi-img,
.pro12-img,
.img-pro-balance,
.img-super-omega {
  width: 60%;
}
.img-icon-avatar {
  width: 40%;
  border-radius: 50%;
  border: 3px solid #94b894;
  padding: 3px;
}
.col-img-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.col-center {
  align-self: center;
  font-size: 18px;
  // font-weight: 600;
  text-transform: uppercase;
}
.gkt-brain {
  width: 150px;
}
.ul-check {
  list-style-type: none;
  margin-top: 10px;
}
.ul-check li {
  position: relative;
  margin-bottom: 5px;
  padding-left: 30px;
}
.ul-check li .v-icon {
  color: #94b894;
  position: absolute;
  left: 0;
}
.h4-icon {
  text-align: center;
  margin-bottom: 10px;
  color: #9dc9b9;
}
.h4-icon span {
  background: #9dc9b9;
  color: #fff;
  font-size: 18px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.micro-div {
  margin-bottom: 25px;
}
.ul-dot {
  list-style-type: none;
}
.ul-dot li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 7px;
}
.ul-dot li .v-icon {
  color: #94b894;
  position: absolute;
  left: 0;
  top: -4px;
}
.h3-contacts {
  text-transform: uppercase;
  color: #4caf50;
}
@media (min-width: 800px) {
  .h2-green {
    font-size: 36px;
  }
  .germany-top {
    width: 70px;
  }
}
@media (max-width: 680px) {
  .reishi-img,
  .pro12-img,
  .img-pro-balance,
  .img-super-omega {
    width: 80%;
  }
  .img-icon-avatar {
    width: 60%;
  }
}
@media (max-width: 600px) {
  .synergy-box {
    display: none;
  }
  .synergy-box-mobile {
    display: block;
    margin: 0 auto;
    text-align: center;
  }
  .synergy-box-mobile img {
    width: 80px;
  }
  .mind-master-pic {
    width: 150px;
  }
  .av-leaf-avatar,
  .honey-avatar {
    width: 60px;
    height: 60px;
  }
  .reishi-img,
  .pro12-img,
  .img-pro-balance,
  .img-super-omega {
    width: 25%;
  }
  .row-icon {
    margin-bottom: 10px;
  }
  .col-img-center {
    padding: 0;
  }
  .col-center {
    font-size: 16px;
  }
  .img-icon-avatar {
    width: 60px;
    height: 60px;
  }
}
@media (max-width: 480px) {
  .av-leaf-avatar,
  .honey-avatar {
    width: 50px;
    height: 50px;
  }
  .img-icon-avatar {
    width: 50px;
    height: 50px;
  }
}
@media (max-width: 430px) {
  .img-icon-avatar {
    width: 45px;
    height: 45px;
  }
  .col-center {
    font-size: 14px;
  }
  .row-icon {
    margin-left: -7px;
  }
}
@media (max-width: 360px) {
  .av-leaf-avatar,
  .honey-avatar {
    width: 40px;
    height: 40px;
  }
}
</style>